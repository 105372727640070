.portfolio {
  margin-top: 72px;

  h4 {
    font-family: $font-family-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: $text-color;
    margin-bottom: 16px;
    padding: spacing(0, 1);
  }

  h2 {
    max-width: 740px;
    font-family: $font-family-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.1px;
    color: $text-color;
    padding: spacing(0, 1);
    margin: auto;
  }

  h5 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
  }

  h6 {
    font-family: $font-family-primary;
    font-size: 16px;
    grid-column: 2;
    margin: 0;
    font-weight: 400;
  }

  svg {
    margin: auto;
  }

  .item {
    text-decoration: none;
    color: inherit;
    display: grid;
    column-gap: 10px;
    grid-template-columns: min-content 1fr;
    align-items: center;
    padding: 32px 0;

    &:hover {
      opacity: .7;
    }

    &:not(:last-child) {
      border: solid #C4C4D1;
      border-width: 0 0 1px 0;
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: -52px;

    h6 {
      grid-column: auto;
    }

    h5 {
      margin: 14px 0 16px;
    }

    .container {
      display: flex;
    }

    .item {
      padding: 0 5px;
      margin: 36px 0 72px 0;
      grid-template-columns: 1fr;
      flex: 1;
      text-align: center;

      &:not(:last-child) {
        border-width: 0 1px 0 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-top: -82px;

    h4 {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 2px;
      margin-bottom: 24px;
    }

    h2 {
      font-size: 22px;
      line-height: 34px;
    }
  }
}