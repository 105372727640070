.blog-subscribe-banner-block {
  $subscribe-icon: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 29 20'%3E%3Cpath d='M18 .24a.82.82 0 0 0 0 1.15l7.78 7.8H.82a.82.82 0 0 0 0 1.63h24.96L18 18.62a.82.82 0 0 0 1.16 1.15l9.18-9.19a.82.82 0 0 0 0-1.15L19.15.23a.82.82 0 0 0-1.16 0Z' fill='%23fff'/%3E%3C/svg%3E");

  padding-top: 40px;

  h1 {
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 24px;

    br {
      display: none;
    }
  }

  .subscribe-form {
    margin-bottom: 40px;

    .hs-email {
      label:not(.hs-error-msg) {
        display: none;
      }

      .input input {
        padding-right: 130px;
      }
    }

    .hs-submit {
      top: 0;
      width: auto;
      background-position: calc(100% - 8px) 50%;
      background-color: $primary-color;
      background-size: 18px auto;
      padding-right: 30px;
      background-image: $subscribe-icon;

      input {
        padding: 0 0 0 16px;
        opacity: 1;
        color: #fff;
      }
    }

    .submitted-message {
      flex-direction: row-reverse;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
      gap: 8px;

      p {
        font-size: 14px;
        line-height: 18px;
      }

      &:after {
        width: 16px;
        height: 16px;
      }
    }
  }

  .description {
    font-weight: 400;
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 28px;
    padding: 0 15px;

    br {
      display: none;
    }

    b {
      font-family: $font-family-secondary;
      text-align: center;
      display: block;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -.1px;
      margin-bottom: 16px;
    }
  }

  img {
    width: 100%;
    margin-bottom: -35px;
    padding: 0 16px 16px 16px;
    z-index: 1;
  }

  .insights {
    background: #FCF9F8;
    border: 1px solid #FCB74F;
    border-radius: 8px;
    padding: 40px 32px;
    display: grid;
    margin-bottom: 32px;
    row-gap: 40px;

    h2 {
      font-weight: 600;
      font-size: 23px;
      line-height: 30px;
      text-align: center;
      margin: 0;
    }

    h3 {
      margin: 0;
      font-family: $font-family-secondary;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -.1px;
    }

    p {
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
    }

    svg {
      display: block;
      width: 40px;
      margin-bottom: 12px;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
    }
  }

  @include media-breakpoint-up(sm) {
    img {
      margin-bottom: -40px;
    }
  }

  @include media-breakpoint-up(md) {
    .container {
      display: grid;
      column-gap: 16px;
      grid-template-columns: 1fr 1fr;
    }

    h1 {
      text-align: left;
      grid-column: span 2;

      br {
        display: initial;
      }
    }

    img {
      margin-bottom: -30px;
    }

    .image {
      display: flex;
      grid-row: 2/ span 2;
      grid-column: 2;
      align-self: end;
    }

    .description {
      padding: 0;
      font-size: 18px;
      line-height: 32px;

      b {
        text-align: left;
        font-size: 22px;
        line-height: 34px;
        margin-bottom: 6px;
      }
    }

    .subscribe-form {
      .submitted-message {
        justify-content: left;
      }
    }

    .insights {
      gap: 24px;
      grid-column: span 2;
      grid-template-columns: 1fr 1fr 1fr;

      h2 {
        grid-column: 1/-1;

        br {
          display: none;
        }
      }

      svg {
        width: 56px;
        height: 56px;
        margin-bottom: 24px;
      }

      div {
        gap: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    h1 {
      font-size: 55px;
      line-height: 70px;
    }

    img {
      margin-bottom: -40px;
    }

    .insights {
      margin-bottom: 48px;

      h2 {
        font-size: 38px;
        line-height: 52px;
      }

      h3 {
        font-size: 22px;
        line-height: 34px;
      }

      p {
        font-size: 18px;
        line-height: 32px;
      }

      div {
        gap: 0;
      }
    }
  }
}