@use "sass:map" as map;

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name);
  @return if($max and $max > 0, $max - .02, null);
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@function _var($name) {
  @return var(--#{$name});
}

@function _spacing($val) {
  @if ($val == 0 or $val == null) {
    @return $val;
  }

  @return if($val > 1 or $val < 1, calc(var(--padding) * #{$val}), var(--padding));
}

@function spacing($top: 1, $right: null, $bottom: null, $left: null) {
  @return _spacing($top) _spacing($right) _spacing($bottom) _spacing($left);
}
