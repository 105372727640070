.simple-cards {
  margin-bottom: 72px;

  .container {
    border-top: 1px solid #C4C4D1;
    padding-top: 120px;
    display: grid;
  }

  h2 {
    font-size: 30px;
    line-height: 40px;
    margin: 0 0 32px 0;
    text-align: center;
  }

  .button {
    margin: auto;
  }

  .card {
    border: 1px solid #C4C4D1;
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 32px;

    img {
      width: 20px;
      display: block;
      margin-bottom: 25px;
    }

    h3 {
      font-family: $font-family-secondary;
      font-weight: 500;
      font-size: 22px;
      line-height: 34px;
      margin-bottom: 10px;
    }

    p {
      font-size: 18px;
      line-height: 32px;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 120px;

    .container {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 30px;
    }

    h2 {
      grid-column: 1/-1;
      margin-bottom: 64px;
      font-size: 38px;
      line-height: 52px;
    }

    .button {
      grid-column: 1/-1;
    }
  }
}