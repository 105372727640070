.skills {
  margin-bottom: 50px;
  padding: spacing(0, 1);

  br {
    display: none;
  }

  .container {
    background: #292944;
    border-radius: 16px;
    box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.1);
    max-width: $container-width-lg;
    padding: 30px;
    text-align: center;
  }

  h2 {
    color: #fff;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 32px;
  }

  p {
    color: $light-color;
    font-family: $font-family-secondary;
    font-size: 19px;
    line-height: 28px;
    margin-bottom: 48px;
  }

  h3 {
    color: $light-color;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;

    > span {
      color: $primary-color;
      display: flex;
      flex-direction: column;
      height: 120px;
      overflow: hidden;
      padding-top: 30px;

      span {
        opacity: .2;
        order: 10;
        transition: margin-top .1s ease-out, opacity .3s ease-out;

        &.prev {
          margin-top: -30px;
          opacity: .5;
          order: 1;
        }

        &.current {
          opacity: 1;
          order: 2;
        }

        &.next {
          opacity: .5;
          order: 3;
        }
      }
    }
  }

  &.blue {
    span {
      color: #086bff;
    }
  }

  @include media-breakpoint-up(md) {
    br {
      display: initial;
    }

    h3 {
      align-items: flex-start;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      text-align: left;

      > span {
        margin: -30px 0 0 10px;
      }
    }

    p {
      color: #fff;
      margin-bottom: 100px;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 90px;

    .container {
      padding: 72px 30px;
    }

    h2 {
      font-size: 38px;
      line-height: 52px;
      margin-bottom: 24px;
    }

    p {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 150px;
    }

    h3 {
      font-size: 38px;
      line-height: 52px;

      > span {
        height: 208px;
        margin: -52px 0 0 30px;
        padding-top: 52px;

        span.prev {
          margin-top: -52px;
        }
      }
    }
  }
}
