.team-block {
  padding: 50px 0;

  h2 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
    text-align: center;
  }

  .container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }

  img {
    border-radius: 12px;
    width: 100%;
  }

  h3 {
    align-items: center;
    border-top: 1px solid $light-color;
    display: grid;
    font-family: $font-family-secondary;
    font-size: 18px;
    gap: 4px;
    grid-template-columns: repeat(auto-fill, 24px);
    line-height: 28px;
    margin: 12px 0 0 0;
    padding-top: 5px;

    span {
      grid-column: 1/-1;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
  }

  p {
    font-size: $text-size;
    line-height: 24px;
    margin: 0;
  }

  &.white {
    background: #fff;
  }

  @include media-breakpoint-up(md) {
    .container {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 100px 0 80px 0;

    h2 {
      font-size: 38px;
      line-height: 52px;
      margin-bottom: 40px;
    }

    .container {
      gap: 40px;
    }

    h3 {
      display: flex;
      font-size: 22px;
      gap: 8px;
      margin: 26px 0 8px 0;
      padding-top: 15px;

      a:first-of-type {
        margin-left: auto;
      }

      svg {
        height: 40px;
        width: 40px;
      }
    }

    p {
      font-size: 18px;
      line-height: 32px;
    }
  }
}