.icons-section {
  background: #fff;
  padding: spacing(2, 1);

  .container {
    align-items: start;
    background: $dark-primary-color;
    border-radius: 16px;
    color: $dark-text-color;
    display: grid;
    gap: 40px;
    max-width: $container-width-lg;
    padding: spacing(3, 2);
  }

  h2 {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }

  h4 {
    font-family: $font-family-secondary;
    font-size: 18px;
    line-height: 30px;
    margin: 0;
  }

  p {
    font-size: $text-size-md;
    line-height: 28px;
    margin: 0;
  }

  .item {
    display: grid;
    gap: 16px;

    &:first-of-type {
      h4 {
        font-size: 24px;
        font-weight: 500;
      }

      p {
        font-family: $font-family-secondary;
        font-size: 18px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .container {
      grid-auto-flow: row dense;
      grid-template-columns: repeat(3, 1fr);
    }

    h2 {
      grid-column: 1/-1;
    }

    .item:first-of-type {
      grid-row: span 2;
    }
  }

  @include media-breakpoint-up(lg) {
    h2 {
      font-size: 38px;
      line-height: 52px;
    }

    .container {
      gap: 80px;
    }

    img {
      width: 40px;
    }

    h4 {
      font-size: 22px;
    }

    p {
      font-size: 18px;
    }

    .item {
      &:first-of-type {
        h4 {
          font-size: 38px;
          line-height: 52px;
        }

        p {
          font-size: 22px;
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .container {
      padding: 72px 120px;
    }
  }
}
