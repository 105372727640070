.testimonials {
  background: var(--bg-color);
  padding-bottom: 72px;
  //margin-bottom: 40px;
  padding-top: 50px;

  &.white {
    background: #fff;
  }

  br {
    display: none;
  }

  h2 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
    text-align: center;

    & + .slides {
      margin-top: 24px;
    }
  }

  p {
    color: $light-color;
    font-family: $font-family-secondary;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 14px;
    text-align: center;
  }

  i {
    color: $primary-color;
    display: block;
    font-family: $font-family-secondary;
    font-size: 18px;
    font-style: normal;
    line-height: 28px;
    margin-bottom: 30px;
    text-align: center;
  }

  img {
    display: block;
    width: 100%;
  }

  .slides {
    background: linear-gradient(131deg, #444460 1.48%, #4e4e8f 100%);
    border-radius: 16px;
    box-shadow: 5px 10px 40px #0000001a;
    display: grid;
    grid-template-areas: "a";
    margin-bottom: 24px;
    overflow: hidden;
  }

  .slide {
    color: #fff;
    grid-area: a;
    opacity: 0;
    transition: opacity 1s ease-out;
    flex-direction: column;
    display: flex;

    div {
      padding: 30px;
    }

    h6 {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 24px;
    }

    h5 {
      font-family: $font-family-secondary;
      font-size: 18px;
      line-height: 28px;
      margin: 0;
    }

    span {
      font-size: $text-size;
      line-height: 24px;
    }

    img {
      height: 260px;
      object-fit: cover;
    }

    &.current {
      opacity: 1;
      z-index: 1;
    }

    &[data-video] {
      cursor: pointer;
      position: relative;

      &::after {
        content: 'Play video';
        background: url('images/play.svg') no-repeat center right;
        margin: auto 30px 30px auto;
        height: 40px;
        display: flex;
        align-items: center;
        padding-right: 50px;
      }
    }
  }

  .pages {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;

    img {
      border-radius: 50%;
      height: auto;
      aspect-ratio: 1/1;
    }

    button {
      background: #fff;
      border: 1px solid #f2eae6;
      border-radius: 50%;
      padding: 2px;
      width: 44px;
      aspect-ratio: 1/1;
    }

    .arrow {
      display: none;
    }

    .current {
      box-shadow: 0 6px 10px #0000001a;
    }
  }

  @include media-breakpoint-up(md) {
    br {
      display: initial;
    }

    .slide {
      display: flex;
      flex-direction: row-reverse;

      img {
        height: 100%;
        width: 250px;
      }

      &[data-video] {
        img {
          padding: 0 30px 0 0;
          object-fit: contain;
        }

        &::after {
          position: absolute;
          bottom: 0;
          margin: 0 30px 30px 0;
        }
      }
    }

    .pages {
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      button {
        padding: 5px;
        width: 54px;
      }

      .arrow {
        background: none;
        border: none;
        display: block;
        height: 30px;
        padding: 0;
        width: 30px;

        &:not(:last-child) {
          margin-left: auto;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 72px;

    h2 {
      font-size: 38px;
      line-height: 52px;

      & + .slides {
        margin-top: 40px;
      }
    }

    .slide {
      div {
        padding: 70px;
      }

      h6 {
        font-size: 38px;
        line-height: 50px;
      }

      h5 {
        font-size: 24px;
        line-height: 30px;
      }

      span {
        font-size: $text-size-md;
        line-height: 28px;
      }

      img {
        width: 350px;
      }

      &[data-video] {
        img {
          padding-right: 68px;
        }

        &::after {
          margin: 0 68px 68px 0;
        }

        &:hover::after {
          opacity: .6;
        }
      }
    }

    .pages {
      gap: 15px;

      button {
        width: 80px;
      }

      .arrow {
        height: 40px;
        width: 40px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .slide {
      img {
        width: 450px;
      }
    }
  }
}
