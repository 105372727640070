.rich-text {
  line-height: 1.7;
  margin: 0 auto;
  max-width: $container-width-sm;
  padding: spacing(1);
  width: 100%;

  hr {
    background-color: currentColor;
    border: 0;
    color: inherit;
    margin: 1rem 0;
    opacity: 0.25;
  }

  hr:not([size]) {
    height: 1px;
  }

  h6, h5, h4, h3, h2, h1 {
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }

  h1 {
    font-size: calc(1.375rem + 1.5vw);
  }

  @media (min-width: 1200px) {
    h1 {
      font-size: 2.5rem;
    }
  }

  h2 {
    font-size: calc(1.325rem + 0.9vw);
  }

  @media (min-width: 1200px) {
    h2 {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: calc(1.3rem + 0.6vw);
  }

  @media (min-width: 1200px) {
    h3 {
      font-size: 1.75rem;
    }
  }

  h4 {
    font-size: calc(1.275rem + 0.3vw);
  }

  @media (min-width: 1200px) {
    h4 {
      font-size: 1.5rem;
    }
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1rem;
  }

  p {
    margin-bottom: 1rem;
    margin-top: 0;
  }

  abbr[title],
  abbr[data-bs-original-title] {
    cursor: help;
    text-decoration: underline dotted;
    text-decoration-skip-ink: none;
  }

  address {
    font-style: normal;
    line-height: inherit;
    margin-bottom: 1rem;
  }

  ol,
  ul {
    padding-left: 15px;
  }

  ol,
  ul,
  dl {
    margin-bottom: 1rem;
    margin-top: 0;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  dt {
    font-weight: 700;
  }

  dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }

  blockquote {
    margin: 0 0 1rem;
  }

  b,
  strong {
    font-family: $font-family-headings;
    font-weight: bolder;
  }

  small {
    font-size: 0.875em;
  }

  mark {
    background-color: #fcf8e3;
    padding: 0.2em;
  }

  sub,
  sup {
    font-size: 0.75em;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  a {
    color: $link-color;
    text-decoration: underline;
  }

  a:hover {
    color: $link-hover;
  }

  a:not([href]):not([class]), a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }

  pre,
  code,
  kbd,
  samp {
    direction: ltr; /* rtl:ignore */
    font-family: var(--bs-font-monospace);
    font-size: 1em;
    unicode-bidi: bidi-override;
  }

  pre {
    display: block;
    font-size: 0.875em;
    margin-bottom: 1rem;
    margin-top: 0;
    overflow: auto;
  }

  pre code {
    color: inherit;
    font-size: inherit;
    word-break: normal;
  }

  code {
    color: #d63384;
    font-size: 0.875em;
    word-wrap: break-word;
  }

  a > code {
    color: inherit;
  }

  kbd {
    background-color: #212529;
    border-radius: 0.2rem;
    color: #fff;
    font-size: 0.875em;
    padding: 0.2rem 0.4rem;
  }

  kbd kbd {
    font-size: 1em;
    font-weight: 700;
    padding: 0;
  }

  figure {
    margin: 0 0 1rem;
  }

  img,
  svg {
    vertical-align: middle;
  }

  table {
    border-collapse: collapse;
    caption-side: bottom;
  }

  caption {
    color: #6c757d;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    text-align: left;
  }

  th {
    text-align: inherit;
    text-align: -webkit-match-parent;
  }

  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }

  .terms-list-fix {
    margin-left: -15px;
  }
}