.learning-system {
  //--length: -> slides count;
  --height-coef: 1.5;
  --image-height: calc(var(--inner-height) - var(--heading-height));

  --inner-height: calc(100vh - var(--top-padding));
  --slide-height: calc(var(--inner-height) * var(--height-coef));

  //--heading-height: -> maximum heading height;
  --total-height: calc(var(--slide-height) * var(--length));

  margin-bottom: 50px;

  --top-padding: 55px;
  padding: spacing(0, 1);

  & + * {
    position: relative;
    z-index: 9;
  }

  br {
    display: none;
  }

  span {
    color: $primary-color;
    display: block;
    font-family: $font-family-secondary;
    font-size: $text-size-xs;
    line-height: $line-height-xs;
    margin-bottom: 24px;
    text-transform: uppercase;
  }

  h2 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
  }

  p {
    font-size: $text-size-md;
    line-height: $line-height-md;
    margin-bottom: 24px;
  }

  .container {
    background: #fff;
    border-radius: 16px;
    margin: auto;
    max-width: $container-width-lg;
    padding: 50px 30px;
    text-align: center;
  }

  .img img, .lg-img img {
    display: block;
    margin: 0 auto;
    max-height: var(--image-height);
    max-width: 300px;
    object-fit: contain;
    padding: spacing(1, 0, 1, 1);
    width: 100%;
  }

  .lg-img {
    display: none;
    grid-column: 1;
    grid-row: 1;
    width: 50%;
    z-index: 1;

    img {
      position: sticky;
      top: var(--top-padding);
      transition: opacity .3s cubic-bezier(.65, 0, .35, 1);
    }
  }

  //.img {
  //  display: flex;
  //}

  .heading {
    padding: spacing(0, 0, .5, 1);
    position: relative;

    img {
      display: block;
      height: 35px;
      margin: 0 10px 0 0;
      width: 35px;
    }

    h3, p {
      transition: opacity .5s cubic-bezier(.65, 0, .35, 1);
    }

    h3 {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      font-family: $font-family-secondary;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 16px;
    }

    p {
      font-size: $text-size;
      line-height: 24px;
      margin: 0;
    }

    &::after {
      background: #fff;
      border: 4px solid $primary-color;
      border-radius: 50%;
      content: "";
      display: block;
      height: 16px;
      position: absolute;
      right: 100%;
      top: spacing(1);
      width: 16px;
    }
  }

  .slides {
    height: var(--total-height);
    margin: auto;
    max-width: 950px;
    text-align: left;
  }

  .line {
    background: #e4e8f1;
    border: 0 solid $primary-color;
    display: block;
    height: var(--slide-height);
    position: absolute;
    right: calc(100% + 6px);
    top: calc(var(--image-height) + var(--padding));
    transition: border-width .2s linear;
    width: 4px;
    will-change: scroll-position;
  }

  .inner {
    align-items: start;
    display: grid;
    grid-template-rows: var(--image-height) var(--heading-height);
    height: var(--inner-height);
    position: sticky;
    top: var(--top-padding);
  }

  .slide {
    height: var(--slide-height);

    &:last-child {
      .line {
        background: #fff;
        height: var(--heading-height);
      }
    }

    &.current ~ .slide {
      .heading::after {
        border-color: #e4e8f1;
      }
    }
  }

  @include media-breakpoint-up(md) {
    --height-coef: 1;
    --image-height: var(--inner-height);

    margin-bottom: 24px;

    --top-padding: 130px;

    br {
      display: initial;
    }

    h2 {
      font-size: 38px;
      line-height: 52px;
    }

    .container {
      padding: 72px 30px;
    }

    .heading {
      padding-top: spacing(1);

      h3, p {
        opacity: 0;
      }

      h3 {
        align-items: flex-start;
        flex-direction: column;
        font-size: 22px;
        line-height: 34px;

        img {
          height: 60px;
          margin: 0 0 20px 0;
          width: 60px;
        }
      }

      p {
        font-size: 18px;
        line-height: 32px;
      }
    }

    .lg-img {
      display: block;
    }

    .inner {
      grid-template-columns: .5fr .5fr;
      grid-template-rows: 0fr;
      height: auto;
      transform: translateY(-100%);
      transition: transform .85s cubic-bezier(.65, 0, .35, 1);
      will-change: scroll-position;
    }

    .line {
      height: 150%;
      right: calc(50% + 6px);
      top: var(--padding);
    }

    .slide {
      --offset: calc(var(--heading-height) + var(--top-padding));

      grid-column: 1;
      grid-row: 1;
      height: 100%;

      &.current {
        .inner {
          transform: translateY(0);
        }

        .heading {
          h3, p {
            opacity: 1;
          }
        }
      }

      &.next {
        .inner {
          transform: translateY(var(--offset));

          .heading {
            h3, p {
              opacity: .3;
            }
          }
        }
      }

      &[data-index="2"] {
        .inner {
          transform: translateY(calc(var(--offset) * 2));

          .heading {
            h3, p {
              opacity: .1;
            }
          }
        }
      }

      &[data-index="3"], &[data-index="4"], &[data-index="5"] {
        .inner {
          transform: translateY(calc(var(--offset) * 3));
        }
      }

      &:last-child {
        .line {
          height: 100%;
        }
      }
    }

    .slides {
      display: grid;
    }

    .img {
      visibility: hidden;
    }
  }
}
