.case-study {
  background: linear-gradient(180deg, #444460 0%, #444460 55%);
  color: #fff;
  overflow: hidden;
  padding: spacing(3, 1);

  h2 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
    text-align: center;
  }

  > h2 {
    margin-bottom: 30px;
    padding: spacing(0, 2);
  }

  img {
    display: block;
    width: 100%;
  }

  br {
    display: none;
  }

  .container {
    margin-bottom: 50px;

    img {
      margin: 0 0 50px 0;
      width: calc(100% + 120px);
    }

    h2 {
      text-align: left;
    }

    p {
      margin: 0;
    }
  }

  .graph {
    background: linear-gradient(310deg, #444460 0%, #5e5e7b 100%);
    border-radius: 6px;
    margin: 0 auto;
    max-width: $container-width;
    padding: spacing(2, 1);
    text-align: center;
    width: 100%;

    span {
      color: $primary-color;
      display: block;
      font-size: $text-size-xs;
      letter-spacing: 1.8px;
      line-height: 16px;
      margin-bottom: 24px;
      text-transform: uppercase;
    }

    h2 {
      padding: spacing(0, 2);
    }

    * {
      margin-left: auto;
      margin-right: auto;
      max-width: 600px;
    }
  }

  @include media-breakpoint-up(md) {
    br {
      display: initial;
    }

    > h2 {
      margin-bottom: 50px;
      padding: 0;
    }

    .container {
      align-items: center;
      column-gap: 40px;
      display: grid;
      grid-template-columns: 40% 1fr;

      img {
        margin: 0;
        order: 1;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 72px spacing(1) 50px;

    h2 {
      font-size: 38px;
      line-height: 52px;
      margin-bottom: 24px;
    }

    > h2 {
      margin-bottom: 90px;
    }

    p {
      font-size: 18px;
      line-height: 32px;
    }

    .container {
      column-gap: 90px;
      grid-template-columns: 35% 1fr;
      margin-bottom: 70px;

      img {
        width: calc(100% + 300px);
      }
    }
  }
}
