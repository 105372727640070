.blog-head {
  margin-top: 8px;
  border-top: 1px solid $text-color;
  padding: 16px 0 24px;
  display: grid;
  gap: 16px;

  h1 {
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0;
    margin: 0;
  }

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: $light-color;
    margin-bottom: 8px;
    font-family: #{$font-family-secondary};
    letter-spacing: -0.1px;

    br {
      display: none;
    }
  }

  .field {
    margin-bottom: 16px;
  }

  .meta {
    display: flex;
    gap: 8px;
    font-family: $font-family-secondary;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    grid-column: 1;
    align-items: center;
    color: $light-color;

    a {
      color: inherit;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      gap: 8px;
    }

    img {
      border-radius: 50%;
    }
  }

  .tags {
    align-items: center;
    font-family: $font-family-secondary;
    display: flex;
    flex-wrap: wrap;
    grid-column: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.266667px;
    text-decoration-line: underline;
    gap: 8px;

    a {
      color: inherit;
      text-decoration: underline;
      display: inline-flex;
      align-items: center;
    }
  }

  nav {
    border: solid $text-color;
    border-width: 1px 0;
    font-family: #{$font-family-secondary};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    flex-wrap: nowrap;
    gap: 24px;
    overflow: auto;
    color: $text-color;

    a {
      padding: 12px 0;
      color: inherit;
      text-decoration: none;
      letter-spacing: 0.233333px;

      &.current {
        position: relative;
        font-weight: 500;

        &::after {
          content: '';
          left: 0;
          right: 0;
          bottom: 0;
          height: 2px;
          background: $text-color;
          position: absolute;
        }
      }

      &:hover {
        color: $primary-color;
      }

      &[aria-disabled="true"] {
        pointer-events: none;
        color: $light-color;
      }
    }
  }

  &.with-back {
    position: relative;
    margin-top: 35px;

    .back {
      position: absolute;
      left: 0;
      top: -30px;
      text-decoration: none;
      font-family: $font-family-secondary;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 1.8px;
      color: $text-color;
      white-space: nowrap;
    }
  }

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: 1fr 250px;
    align-items: center;
    margin-top: 30px;

    h1 {
      font-size: 38px;
      line-height: 44px;
    }

    p {
      grid-column: 1;

      br {
        display: initial;
      }
    }

    .field {
      margin: 0;
      grid-column: 2;
      grid-row: 1;
    }

    .meta {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 2px;

      a {
        gap: 12px;

        img {
          width: 56px;
          height: 56px;
        }
      }
    }

    .tags {
      gap: 16px;
    }

    nav {
      grid-column: 1/-1;
      font-size: 16px;
      line-height: 20px;
    }

    &.with-back {
      margin-top: 40px;

      .back {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 1.5px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 325px;

    h1 {
      font-size: 55px;
      line-height: 70px;
    }

    p {
      font-size: 22px;
      line-height: 32px;

      br {
        display: initial;
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    &.with-back {
      margin-top: 30px;

      .back {
        top: -8px;
        left: auto;
        right: calc(100% + 30px);
      }
    }
  }
}

.blog-tag-group {
  margin: 12px auto;
  padding: 24px 0;
  border-top: 1px solid $text-color;
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 8px;

  .title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin: 0;
    display: flex;

    & + .blog-post-card {
      grid-column: span 2;
    }
  }

  .more {
    font-family: $font-family-secondary;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.8px;
    text-decoration: none;
    color: $text-color;
    margin-left: auto;
    align-self: center;
    white-space: nowrap;
  }

  .blog-post-card {
    grid-column: 1/-1;
  }

  .items {
    grid-column: 1/-1;
    display: flex;
    gap: 16px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }

    .blog-post-card {
      width: 100%;
      flex-shrink: 0;
      scroll-snap-align: start;

      transform-origin: center center;
      transform: scale(1);
      transition: transform 0.5s;
    }
  }

  .pages {
    grid-column: 1/-1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    margin-top: 8px;

    span {
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #e4e8f1;
      cursor: pointer;

      &.current {
        background: $light-color;
      }
    }
  }

  &.last {
    margin-bottom: 24px;
  }

  &.glossary {
    .blog-head + & {
      border-top: none;
      padding-top: 0;
      margin-top: 0;
    }
  }

  @include media-breakpoint-up(md) {
    gap: 24px;
    margin-bottom: 24px;
    grid-template-columns: repeat(3, 1fr);

    .title {
      font-size: 38px;
      line-height: 52px;
      grid-column: span 2;

      & + .blog-post-card {
        grid-column: 1;
      }
    }

    .more {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 2px;
      justify-self: end;
    }

    .blog-post-card {
      grid-column: auto;
    }

    .items {
      scroll-snap-type: none;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    .pages {
      display: none;
    }

    &.last {
      margin-bottom: 48px;
    }
  }

  @include media-breakpoint-up(lg) {
    gap: 45px;

    .title, .more {
      margin-bottom: -21px;
    }

    .items {
      gap: 45px;
    }
  }
}

.blog-pagination {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 40px 0;

  a {
    text-decoration: none;
    font-size: 16px;
    line-height: 20px;
    color: $text-color;
    width: 15px;
    text-align: center;
    border-bottom: 1px solid transparent;

    &[href=""], &:not([href]) {
      color: #c4c4d1;
      pointer-events: none;
    }

    &.current {
      font-weight: 500;
      border-color: currentColor;
    }

    &:hover {
      border-color: currentColor;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 72px 0;
  }
}

.blog-post-card {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-decoration: none;
  color: $text-color;
  font-family: $font-family-primary;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  img {
    width: 100%;
    height: auto;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
  }

  b {
    font-family: $font-family-secondary;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.1px;
  }

  span {
    display: -webkit-box;
    max-height: 72px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .read-more {
    font-family: $font-family-secondary;
    font-weight: 500;
    letter-spacing: 1.8px;
    color: $text-color;
    text-align: right;
  }

  &:not(.glossary):hover b {
    color: $primary-color;
  }

  &.glossary:hover .read-more {
    color: $primary-color;
  }

  @include media-breakpoint-down(md) {
    &.simple {
      flex-direction: row;
      gap: 16px;
      margin-top: 8px;
      padding-bottom: 16px;
      border-bottom: 1px solid #c4c4d1;

      img {
        width: 134px;
        height: 134px;
      }

      span {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(md) {
    gap: 8px;

    span {
      max-height: 56px;
      -webkit-line-clamp: 2;
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: 16px;
    line-height: 28px;

    b {
      margin-top: 8px;
      font-size: 22px;
      line-height: 34px;
    }
  }
}

.blog-featured {
  padding-bottom: 12px;

  a {
    display: grid;
    gap: 8px;
    color: $text-color;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-decoration: none;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid $text-color;

    img {
      width: 100%;
      height: auto;
    }

    .title {
      font-family: $font-family-headings;
    }

    .meta {
      font-family: $font-family-secondary;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 1.8px;
      text-transform: uppercase;
      color: $light-color;
    }

    .excerpt {
      font-size: 14px;
      line-height: 24px;
      max-height: 120px;
      font-weight: 400;
      overflow: hidden;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }

    &.main {
      text-align: center;
      padding-bottom: 24px;

      .title {
        margin-top: 16px;
      }
    }

    &.secondary {
      grid-template-columns: 1fr 120px;
      column-gap: 16px;

      .title, .meta {
        grid-column: 1/-1;
      }
    }

    &.other {
      border-bottom-color: #c4c4d1;
      gap: 4px;
      padding-bottom: 24px;
      margin-bottom: 24px;

      .title {
        font-family: $font-family-secondary;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
      }

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  .wrap {
    &::before {
      content: attr(data-title);
      font-family: $font-family-secondary;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 1.8px;
      margin: 24px 0;
      display: block;
    }
  }

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: .7fr .3fr;
    align-items: start;

    a {
      &.main {
        grid-column: 1/-1;
      }

      &.secondary {
        grid-column: 1;
        margin-right: 16px;
        grid-template-columns: 200px 1fr;
        grid-template-rows: min-content min-content 1fr;

        img {
          grid-column: 1;
          grid-row: 1/span 3;
        }

        .title {
          grid-column: auto;
        }

        .meta {
          grid-column: auto;
        }

        & + .secondary {
          margin-bottom: 0;
          border-bottom: 0;
          padding-bottom: 0;
          grid-template-columns: 1fr 200px;

          img {
            grid-column: 2;
          }
        }
      }

      &.other {
        .title {
          display: -webkit-box;
          max-height: 56px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }

    .wrap {
      grid-column: 2;
      grid-row: 2/span 3;
      padding-left: 16px;
      border-left: 1px solid $text-color;
      align-self: stretch;

      &::before {
        margin-top: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    a {
      gap: 16px;

      .meta {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 2px;
      }

      .excerpt {
        font-size: 16px;
        line-height: 28px;
        max-height: 140px;
      }

      &.main {
        margin-bottom: 24px;

        .title {
          margin-top: 8px;
          font-size: 38px;
          line-height: 52px;
        }
      }

      &.secondary {
        column-gap: 24px;
        grid-template-columns: 275px 1fr;
        margin-right: 24px;
        margin-bottom: 24px;
        padding-bottom: 24px;

        .title {
          font-size: 38px;
          line-height: 52px;
        }

        & + .secondary {
          grid-template-columns: 1fr 275px;
        }
      }

      &.other {
        .title {
          font-size: 22px;
          line-height: 34px;
          max-height: 68px;
        }
      }
    }

    .wrap {
      padding-left: 24px;

      &::before {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 2px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    a {
      &.secondary {
        grid-template-columns: 325px 1fr;
        column-gap: 30px;

        & + .secondary {
          grid-template-columns: 1fr 325px;
        }
      }
    }
  }
}

.blog-single {
  border-top: 1px solid $text-color;
  padding: 24px 0 48px;
  font-size: 16px;
  line-height: 28px;

  a:not([class]) {
    color: $link-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  strong, b {
    font-family: $font-family-headings;
    font-weight: 600;
  }

  p {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul, ol {
    margin: 16px 0;
    padding-left: 25px;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 24px 0 16px 0;
    line-height: 1.4;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 24px;
  }

  blockquote {
    font-family: $font-family-headings;
    padding: 24px 30px;
    border: solid $text-color;
    border-width: 1px 0;
    margin: 32px 0;
    font-style: italic;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;

    q::before {
      margin-left: -10px;
    }

    b, strong, cite {
      display: block;
      font-family: $font-family-secondary;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.233333px;
      margin-top: 8px;
      font-weight: 400;
      color: $light-color;
      font-style: normal;
    }
  }

  .kg-card {
    width: 100%;
    display: block;
    margin: 0 auto 16px;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      display: block;
    }

    figcaption {
      font-family: $font-family-secondary;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.233333px;
      color: $light-color;
      margin-top: 8px;
    }

    &:first-child {
      padding-bottom: 24px;
      border-bottom: 1px solid $text-color;
    }
  }

  .kg-embed-card {
    aspect-ratio: 16 / 9;
    display: grid;
    grid-template-rows: 1fr min-content;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .kg-width-wide {
    width: 100%;
  }

  .kg-width-full {
    width: calc(100% + 40px);
    margin: 0 -20px 16px -20px;
  }

  .subscribe-form {
    width: 100%;
    margin: 0 0 32px 0;
  }

  @include media-breakpoint-up(md) {
    position: relative;
    display: grid;
    grid-template-columns: .7fr .3fr;
    column-gap: 24px;

    > * {
      grid-column: 1;
    }

    .kg-width-full, .kg-width-wide, blockquote {
      grid-column: 1/-1;
    }

    .kg-embed-card {
      grid-column: 1;
      width: 100%;
      margin-left: 0;
    }

    blockquote {
      position: relative;
      display: grid;
      grid-template-columns: .7fr .3fr;
      gap: 24px;
      border-bottom-width: 0;

      &::after {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: calc(70% - 24px);
        border-bottom: 1px solid $text-color;
      }
    }

    h2 {
      font-size: 32px;
    }

    h3 {
      font-size: 28px;
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: 18px;
    line-height: 32px;

    .kg-width-full {
      width: 100%;
      margin: 0 0 16px 0;
    }

    blockquote {
      font-size: 38px;
      line-height: 52px;

      b, strong, cite {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.266667px;
      }
    }
  }
}

.blog-inner-cta {
  background: $primary-color;
  padding: 30px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  margin: 32px 0;
  gap: 8px;

  .title {
    font-size: 30px;
    line-height: 39px;
    color: $dark-text-color;
    margin: 0 0 80px 0;
  }

  @include media-breakpoint-up(md) {
    grid-column: 2;
    grid-row: span 5;
    align-self: start;
  }
}

.blog-share {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  gap: 8px;

  svg {
    display: block;
    width: 40px;
    height: 40px;
  }

  @include media-breakpoint-up(md) {
    grid-column: 2;
    grid-row: span 3;
    align-self: start;
    flex-direction: column;
    align-items: end;

    a {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;

      &::before {
        content: attr(title);
        letter-spacing: 2px;
        text-transform: uppercase;
        font-family: $font-family-secondary;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $text-color;
      }

      svg {
        border: 1px solid $text-color;
        border-radius: 4px;
      }

      &:hover {
        &::before {
          text-decoration: underline
        }

        svg {
          background: $text-color;
        }

        path {
          filter: invert(100%);
        }
      }
    }
  }
}

.blog-author {
  display: grid;
  margin-top: 32px;
  padding-top: 16px;
  border-top: 1px solid $text-color;
  grid-template-columns: 40px 1fr;
  align-items: center;
  column-gap: 8px;
  row-gap: 2px;

  .photo {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    grid-column: 1;
    grid-row: 1;
  }

  .name {
    font-family: $font-family-secondary;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    text-decoration: none;
    color: $text-color;
    grid-row: 1;
    grid-column: 2;
  }

  .position {
    font-family: $font-family-secondary;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: $light-color;
    align-self: start;
    grid-row: 2;
    grid-column: 2;

    & + .name {
      align-self: end;
    }

    & ~ .photo {
      grid-row: 1/span 2;
    }
  }

  .bio {
    grid-column: 1/-1;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin: 14px 0 6px 0;
    padding-top: 8px;
    border-top: 1px solid $text-color;
  }

  .twitter {
    font-family: $font-family-secondary;
    grid-column: 1/-1;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    text-decoration: none;
    color: $text-color;
    font-weight: 500;

    b {
      font-weight: 700;
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 80px 1fr;
    column-gap: 20px;
    padding-top: 24px;
    row-gap: 8px;

    .photo {
      width: 80px;
      height: 80px;
    }

    .name {
      font-size: 14px;
      line-height: 18px;
    }

    .position {
      font-size: 14px;
      line-height: 18px;
    }

    .bio {
      font-size: 16px;
      line-height: 28px;
      margin: 16px 0 8px 0;
      padding-top: 16px;
    }

    .twitter {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 2px;
    }
  }
}

.blog-subscribe-cta {
  background: #FCB74F;
  border-radius: 16px;
  display: grid;
  gap: 24px;
  padding: spacing(3, 2, 0, 2);
  margin-bottom: 32px;

  h2 {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #000000;

    i {
      display: block;
    }
  }

  img {
    max-width: 255px;
    width: 100%;
    margin: 0 auto -12px auto;
  }

  .subscribe-form {
    width: 100%;

    .hs-email {
      label:not(.hs-error-msg) {
        display: none;
      }

      input {
        background: #fff;
      }
    }

    .hs-form-booleancheckbox-display input {
      accent-color: #000;
    }

    .hs-submit {
      position: static;
      border-radius: 3px;
      width: auto;
      background: #000;

      input {
        color: #fff;
        padding: 0 16px;
        opacity: 1;
      }
    }

    form {
      position: static;
    }

    .submitted-message {
      align-items: center;

      p {
        font-family: $font-family-headings;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr;
    padding: spacing(3, 2, 0, 2);

    img {
      grid-row: 1/span 2;
      max-width: initial;
      align-self: end;
      margin-bottom: -14px;
    }

    h2 {
      font-weight: 600;
      text-align: left;
    }

    .subscribe-form {
      max-width: 100%;
      margin-bottom: spacing(3);

      .submitted-message {
        p {
          white-space: nowrap;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    h2 {
      font-size: 38px;
      line-height: 52px;
      white-space: nowrap;
      margin-bottom: 8px;
    }

    img {
      margin-bottom: -20px;
    }

    .subscribe-form {
      .submitted-message {
        flex-direction: row;
        gap: 8px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    img {
      margin-bottom: -25px;
    }

    .subscribe-form {
      .submitted-message {
        p {
          font-size: 38px;
          font-weight: 600;
          line-height: 52px;
          text-transform: none;
        }
      }
    }
  }
}

@media print {
  #cart-cta-banner {
    display: none;
  }

  .header {
    display: none;
  }

  .blog-head {
    display: block;

    .back {
      display: none;
    }

    &.with-back {
      border-top: none;
    }

    .tags {
      display: none;
    }
  }

  .blog-share {
    display: none;
  }

  .blog-single {
    display: block;

    .kg-image-card.thumb, .kg-embed-card {
      display: none;
    }
  }

  .blog-author {
    display: none;
  }

  .blog-tag-group {
    display: none;
  }

  .blog-subscribe-cta {
    display: none;
  }

  .blog-inner-cta {
    display: none;
  }

  .footer {
    display: none;
  }

  #hb-form {
    display: none;
  }
}