.pricing {
  background: $dark-primary-color;
  padding: 50px 0 50px 0;

  br {
    display: none;
  }

  h1, h2 {
    color: $dark-text-color;
    font-size: 24px;
    line-height: 1.25;
    margin-bottom: 24px;
    padding: spacing(0, 2);
    text-align: center;
  }

  .price {
    align-items: start;
    border: solid $dark-text-color;
    border-width: 1px 0;
    color: $dark-text-color;
    column-gap: 8px;
    display: grid;
    grid-template-columns: max-content max-content;
    margin: 32px auto 45px;
    max-width: max-content;
    padding: 8px 0;

    span {
      font-size: 30px;
      grid-row: span 2;
    }

    small {
      font-size: $text-size;

      &:first-of-type {
        align-self: end;
      }
    }
  }

  .tabs {
    margin-top: 40px;
    overflow: auto;

    div {
      align-items: center;
      display: inline-flex;
      gap: 24px;
      justify-content: center;
      padding: 0 16px 40px 16px;
    }

    a {
      white-space: nowrap;
      align-items: center;
      color: $light-color;
      display: inline-flex;
      font-family: $font-family-secondary;
      font-size: $text-size;
      gap: 8px;
      text-decoration: none;
      text-transform: uppercase;

      &.active, &:hover {
        color: $dark-text-color;
        text-decoration: underline;
      }
    }
  }

  .plan {
    background: $dark-light-color;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    padding: 24px 30px;

    h3 {
      color: #fff;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 32px;
      text-align: center;
    }

    h4 {
      color: #fff;
      font-family: $font-family-secondary;
      font-size: $text-size-md;
      font-weight: 400;
      line-height: 20px;
      margin: 0 0 8px 32px;
    }

    .button {
      margin-top: auto;
      width: 100%;
    }

    ul {
      color: #fff;
      display: grid;
      font-size: $text-size-md;
      gap: 8px;
      line-height: 24px;
      margin: 0 0 32px 0;
      padding: 0;

      li {
        display: grid;
        gap: 16px;
        grid-template-columns: 16px 1fr;

        &::before {
          background: url("images/list-check.svg") no-repeat 100%;
          content: "";
          height: 16px;
          margin-top: 5px;
          width: 16px;
        }
      }
    }
  }

  .actions {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 56px;

    .button {
      display: flex;
      max-width: 300px;
      width: 100%;

      svg {
        margin-right: 10px;
      }
    }
  }

  .icons {
    margin-top: 64px;

    h4 {
      color: #e4e8f1;
      font-family: $font-family-secondary;
      font-size: 18px;
      line-height: 28px;
      margin: 0 auto 30px auto;
      text-align: center;
      width: 300px;
    }

    div {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      margin: auto;
      width: 200px;
    }

    img {
      background: #fff;
      border-radius: 50%;
      height: 40px;
      object-fit: contain;
      padding: 5px;
      width: 40px;
    }
  }

  @include media-breakpoint-up(md) {
    h1 {
      font-size: 38px;
    }

    br {
      display: initial;
    }

    .container {
      display: grid;
      gap: 16px;
      justify-content: center;
      grid-template-columns: repeat(2, .5fr);
    }

    .price {
      span {
        font-size: 55px;
        line-height: 70px;
      }

      small {
        font-size: $text-size-md;
      }
    }

    .tabs {
      display: flex;
      justify-content: center;

      svg {
        width: 24px;
        height: 22px;
      }
    }

    .plan {
      margin: 0;
      padding: 30px 24px;
    }

    .icons {
      margin-top: 64px;

      h4 {
        width: auto;
      }

      div {
        width: 350px;
      }

      img {
        height: 80px;
        width: 80px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 72px 0 90px 0;

    h1, h2 {
      font-size: 38px;
      line-height: 52px;
      margin-bottom: 48px;
    }

    h1 {
      font-size: 55px;
    }

    .plan {
      h3 {
        font-size: 38px;
        line-height: 52px;
        margin-bottom: 20px;
      }

      li {
        font-size: $text-size-md;
        line-height: 28px;
      }
    }

    .container {
      margin-bottom: 64px;
      grid-template-columns: repeat(2, .33fr);
    }

    .bottom {
      .button {
        margin-bottom: 64px;
      }
    }

    .icons {
      h4 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 24px;
      }

      div {
        width: auto;
      }
    }
  }
}

.program-comparison {
  padding: 50px 0;

  .container {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    * {
      margin: 0;
    }
  }

  ._borders {
    align-items: center;
    border: solid #e4e8f1;
    border-width: 0 1px;
    color: $light-color;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    padding: 15px 0;
    text-align: center;

    &.nth-2 {
      border-width: 0;
    }
  }

  .title {
    font-size: 30px;
    grid-column: 1/-1;
    margin-bottom: 50px;
    text-align: center;
  }

  .group {
    font-size: 24px;
    grid-column: 1/-1;
    line-height: 30px;
    margin: 16px 0 32px;
  }

  .level {
    font-family: $font-family-secondary;
    font-size: 18px;

    &.empty {
      display: none;
    }

    @extend ._borders;
  }

  .label {
    font-size: $text-size-md;
    grid-column: 1/-1;
    margin-bottom: 16px;
  }

  .mark {
    @extend ._borders;
  }

  .text {
    font-size: $text-size-md;

    @extend ._borders;
  }

  .price {
    flex-direction: column;
    font-size: $text-size-md;

    @extend ._borders;
  }

  @include media-breakpoint-up(md) {
    .container {
      grid-template-columns: 1fr repeat(3, max-content);
    }

    ._borders {
      border-width: 0;
      color: $text-color;
      margin: 0;
      padding: 25px;

      &.nth-2 {
        border-width: 0 1px;
      }
    }

    .title {
      font-size: 38px;
    }

    .level {
      &:not(.empty) {
        padding-bottom: 0;
        padding-top: 0;
      }

      &.empty {
        display: flex;
      }

      &.nth-1 {
        grid-column: 2;
      }
    }

    .group {
      grid-column: 1;
      margin: 40px 0 0 0;
    }

    .label {
      grid-column: 1;
      margin: 0;
    }

    .price {
      span {
        display: inline;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .title {
      font-size: 55px;
      margin-bottom: 80px;
    }

    ._borders {
      font-size: 18px;
    }

    .level {
      font-size: 22px;
    }

    .group {
      font-size: 38px;
    }

    .price {
      flex-direction: row;
    }
  }
}