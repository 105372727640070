.amz-pay {
  margin-bottom: 48px;

  ._title {
    font-weight: 600;
    font-size: 55px;
    line-height: 68px;
    margin: 0 0 20px 0;
  }

  ._desc {
    font-size: 18px;
    line-height: 32px;
    color: $light-color;
  }

  ._outside_lnk {
    color: $light-color;
    font-size: 14px;
    line-height: 18px;
    grid-column: auto;

    & + button {
      grid-column: auto;
    }
  }

  ._price_notice {
    grid-column: auto;
    font-family: $font-family-secondary;

    h3 {
      margin-bottom: 8px;
      font-family: $font-family-secondary;
    }
  }

  .form {
    margin-top: 30px;
    background: #fff;
    box-shadow: 0 127px 51px rgba(53, 37, 29, 0.01), 0 72px 43px rgba(53, 37, 29, 0.05), 0 32px 32px rgba(53, 37, 29, 0.09), 0 8px 17px rgba(53, 37, 29, 0.05), 0 0 0 rgba(53, 37, 29, 0.05);
    border-radius: 16px;
    padding: 32px 32px 50px 32px;
  }

  svg {
    margin: auto;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 133px;

    .container {
      display: grid;
      grid-template-columns: 1fr 680px;
      gap: 16px;
    }

    .form {
      margin: 0;
    }
  }
}