.faq {
  background: #fff;
  padding: 40px 0 0 0;

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    max-width: 680px;
    text-align: center;
    margin: 0 auto 16px;
  }

  .container {
    max-width: 740px;
  }

  h2 {
    font-weight: 600;
    font-size: 23px;
    line-height: 30px;
    text-align: center;
    color: $text-color;
    margin: 0 0 16px 0;
  }

  h3 {
    font-family: $font-family-secondary;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.1px;
    margin: 0;
    color: $text-color;
  }

  .item {
    display: grid;
    padding: 24px 0;
    gap: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #444460;

    &:before {
      content: attr(data-index);
      width: 32px;
      height: 32px;
      background: $primary-color;
      color: #fff;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      font-family: $font-family-secondary;
      font-weight: 500;
      font-size: 18px;
    }

    &:not(:last-of-type) {
      position: relative;

      &:after {
        content: '';
        border-bottom: 1px solid #E4E8F1;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  .caption {
    border: 1px solid #FCB74F;
    border-radius: 16px;
    padding: 24px;
    margin: 8px 0 0 0;
  }

  .details {
    margin: 32px spacing(1) 0;
    padding: 32px 24px;
    background: #FCF9F8;
    max-width: 915px;
    border-radius: 16px;

    li {
      list-style: none;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 24px;

      &:first-child {
        font-family: $font-family-headings;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: #000000;
        margin-bottom: 16px;
      }

      &:not(:first-child) {
        display: grid;
        grid-template-columns: min-content 1fr;
        gap: 10px;

        &:before {
          content: '';
          background: url("images/list-check-orange.svg") no-repeat 100%;
          border-radius: 50%;
          width: 12px;
          height: 12px;
          display: block;
          margin-top: 6px;
          background-size: 100%;
        }
      }
    }

    @media (min-width: 915px) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.two-columns {
    .container {
      max-width: 1150px;
    }
  }

  @include media-breakpoint-up(md) {
    .item {
      grid-template-columns: min-content 1fr;
      column-gap: 32px;
      row-gap: 16px;

      &:before {
        grid-row: span 2;
      }

      &:not(:last-child):after {
        left: 64px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 72px;

    .description {
      font-size: 18px;
      line-height: 32px;
      margin-top: -8px;
    }

    h2 {
      font-size: 38px;
      line-height: 52px;
      margin-bottom: 24px;
    }

    h3 {
      font-size: 22px;
      line-height: 34px;
    }

    .item {
      font-size: 18px;
      line-height: 32px;

      &:before {
        font-size: 22px;
        width: 40px;
        height: 40px;
      }

      &:not(:last-child):after {
        left: 72px;
      }
    }

    .caption {
      font-size: 16px;
      line-height: 28px;
    }

    .details {
      padding: 48px 60px;
      margin-top: 40px;
      columns: 2;
      gap: 40px;

      li {
        font-size: 16px;
        line-height: 28px;
      }

      li:first-child {
        font-size: 18px;
        line-height: 28px;
        column-span: all;
      }

      li:not(:first-child) {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 16px;

        &:before {
          margin-top: 8px;
        }
      }
    }

    &.two-columns {
      h2 {
        column-span: all;
      }

      .description {
        column-span: all;
      }

      .container {
        gap: 32px;
        columns: 2;
      }
    }
  }
}