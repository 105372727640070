.columns {
  > * {
    max-width: $container-width-sm;
    margin: 0 auto 50px;
    padding: 40px spacing(1) 0;
  }

  &.bg-white {
    background: #fff;
  }

  h2 {
    font-size: 38px;
    line-height: 52px;
    margin: 0 0 60px 0;
    column-span: all;
  }

  h4 {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    margin: 0 0 26px 0;
  }

  ul {
    margin: 0 0 42px 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    padding-left: 30px;
    list-style-type: circle;
  }

  &.amz-skls {
    strong {
      font-family: $font-family-secondary;
      font-weight: 500;
      font-size: 22px;
      line-height: 34px;
      display: block;
      margin-bottom: -15px;
    }

    p {
      margin-bottom: 25px;
      font-size: 18px;
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: 72px;

    @for $i from 1 through 12 {
      &.col-#{$i} > * {
        column-count: $i;
        column-gap: 15px;
      }
    }
  }
}