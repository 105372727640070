.image-block {
  background: $dark-primary-color;
  color: #fff;
  padding-top: 64px;

  img {
    margin-top: 48px;
    width: 100%;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .container {
    display: flex;
    flex-direction: column;
    font-size: $text-size-md;
  }

  p {
    font-size: $text-size-md;
  }

  @include media-breakpoint-up(md) {
    padding: 0;

    .container {
      align-items: center;
      flex-direction: row;
    }

    .content, img {
      flex-basis: 50%;
      width: 50%;
    }
  }

  @include media-breakpoint-up(lg) {
    h2 {
      font-size: 38px;
    }

    .container {
      font-size: 18px;
      gap: 50px;
    }

    p {
      font-size: 18px;
      line-height: 32px;
    }
  }

  @include media-breakpoint-up(xl) {
    .content {
      flex-basis: 40%;
      width: 40%;
    }

    img {
      flex-basis: 60%;
      margin-top: 85px;
      width: 60%;
    }

    .container {
      gap: 100px;
    }
  }
}