.not-found {
  line-height: $line-height-xs;
  padding: 50px spacing(1);
  text-align: center;

  h1 {
    font-size: 55px;
    margin-bottom: 16px;
  }

  h3 {
    font-size: 22px;
    margin-bottom: 16px;
  }

  img {
    margin-bottom: 24px;
  }
}