.opt-out-banner {
  margin: 0 auto;
  max-width: $container-width-sm;
  overflow: hidden;
  padding: 72px spacing(1) 50px;

  h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }

  .hbspt-form {
    margin-top: 32px;
  }
}
