.graph {
  background: #fff;

  h2 {
    font-size: 23px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 16px;
  }

  .description {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    max-width: 680px;
    margin: 0 auto 24px auto;
  }

  .container {
    padding-bottom: 60px;
  }

  .graph {
    overflow: hidden;
    position: relative;

    &:before {
      content: "CLICK TOP THINKER TO LEARN MORE";
      position: absolute;
      top: 0;
      white-space: nowrap;
      left: 50%;
      transform: translateX(-50%);
      font-family: $font-family-secondary;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 1.8px;
      text-transform: uppercase;
      color: $light-color;
    }

    .vis-network {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .item {
    padding-bottom: 32px;
    display: grid;
    grid-template-columns: min-content 56px 1fr;
    row-gap: 8px;
    column-gap: 16px;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background: $primary-color;
      border-radius: 50%;
      align-self: center;
    }

    &:after {
      content: '';
      top: 8px;
      left: 5px;
      width: 1px;
      height: 100%;
      background: $primary-color;
      position: absolute;
    }

    &:first-child {
      margin-top: 40px;
    }

    &:last-child {
      margin-bottom: 40px;
      padding-bottom: 0;

      &:after {
        content: none;
      }
    }

    img {
      border-radius: 50%;
      border: 3px solid $primary-color;
      width: 100%;
      grid-column: 2;
      grid-row: span 2;
    }

    h3 {
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      letter-spacing: -0.1px;
      grid-column: 2/-1;
      margin: 0;
      font-family: $font-family-secondary;
    }

    h4 {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 2px;
      text-transform: uppercase;
      grid-column: 3;
      margin: 0;
      align-self: end;
      font-family: $font-family-secondary;
    }

    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      grid-column: 3;
      color: $light-color;
      margin: 0;
      font-family: $font-family-secondary;
    }
  }

  @include media-breakpoint-up(lg) {
    h2 {
      font-size: 38px;
      line-height: 52px;
    }

    .description {
      font-size: 16px;
      line-height: 28px;
    }

    .graph {
      aspect-ratio: 1.3/1;

      &:before {
        content: 'CLICK TO EXPLORE';
      }
    }
  }
}

#graph-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 20%);
  z-index: 10000;
  align-items: end;
  display: grid;

  div {
    position: relative;
    padding: 32px;
    display: grid;
    background: #fff;
    border-radius: 16px 16px 0 0;
    grid-template-columns: 58px 1fr;
    column-gap: 12px;
  }

  button {
    position: absolute;
    top: 32px;
    right: 32px;
  }

  img {
    width: 100%;
    grid-row: span 2;
    border-radius: 50%;
  }

  h2 {
    font-family: $font-family-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.1px;
    margin-bottom: 3px;
    align-self: end;
  }

  h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $light-color;
    opacity: 0.8;
    margin: 0;
  }

  h4 {
    grid-column: 1/-1;
    font-family: $font-family-secondary;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin-bottom: 32px;
    line-height: 31px;
  }

  p {
    margin: 24px 0 0 0;
    grid-column: 1/-1;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  i {
    display: block;
    margin-bottom: 8px;
  }

  @include media-breakpoint-up(md) {
    align-items: center;
    justify-items: center;

    div {
      width: 680px;
      border-radius: 8px;
      padding: 32px 72px 40px 72px;
      grid-template-columns: 93px 1fr;
      column-gap: 16px;
    }

    h2 {
      font-size: 22px;
      margin-bottom: 8px;
    }

    h3 {
      font-size: 18px;
    }

    h4 {
      text-align: center;
    }

    p {
      font-size: 16px;
      line-height: 28px;
    }
  }
}