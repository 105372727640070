.cta {
  color: #fff;
  padding: 50px spacing(1);
  text-align: center;

  //* {
  //  color: #fff;
  //  text-decoration: none;
  //}

  h2 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
  }

  p {
    margin: 0 auto 30px auto;
    max-width: 670px;
  }

  .container {
    background: linear-gradient(197deg, #eaa22d 0%, #ea792d 100%);
    border-radius: 16px;
    max-width: $container-width-lg;
    padding: 72px 30px;
    position: relative;

    &::before {
      background: url("images/cta-bg.png") no-repeat bottom left;
      background-size: 50%;
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .actions {
    display: grid;
    gap: 10px;
    position: relative;
  }

  .params {
    display: flex;
    gap: 10px;
    margin: 0 auto;
    max-width: $container-width-sm;
    position: relative;
    text-align: left;
    flex-direction: column;

    h3 {
      font-size: 22px;
      margin-bottom: 10px;
    }

    > div {
      &:not(:last-child) {
        border: solid #fff3;
        border-width: 0 0 1px 0;
        padding: 0 0 10px 0;
      }
    }

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .subscribe-form {
    margin: auto;
    text-align: left;

    label span {
      color: #fff;
    }

    .hs-email .input {
      background: #fff;
    }

    .field .hs-form-booleancheckbox-display input {
      accent-color: #fff;
    }

    .hs-submit {
      top: 8px;
    }

    .hs-error-msgs li {
      color: #fff;
    }

    .submitted-message {
      align-items: center;

      p {
        color: #fff;
      }
    }
  }

  br {
    display: none;
  }

  &.contact-us {
    .container {
      border-radius: 0;
      max-width: $container-width-sm;

      &::before {
        content: none;
      }
    }
  }

  &.full {
    padding: 0;

    .container {
      padding: 54px 45px;
      border-radius: 0;
      max-width: initial;
    }
  }

  &.white {
    background: #fff;
  }

  @include media-breakpoint-up(md) {
    padding-bottom: 24px;

    .container {
      padding: 70px;

      &::before {
        background-size: 30%;
      }
    }

    .actions {
      grid-template-columns: 0fr 0fr;
      justify-content: center;
    }

    .params {
      flex-direction: row;

      > div {
        flex: 1;

        &:not(:last-child) {
          border-width: 0 1px 0 0;
          padding: 0 10px 0 0;
        }
      }
    }

    br {
      display: initial;
    }
  }

  @include media-breakpoint-up(lg) {
    h2 {
      font-size: 38px;
      line-height: 52px;
      margin-bottom: 32px;
    }

    &.full .container {
      padding: 110px 50px;

      p {
        font-size: 18px;
        line-height: 32px;
      }
    }
  }
}
