.cart-block {
  padding: 72px 0;

  h1 {
    font-size: 30px;
    grid-column: 1/-1;
    margin-bottom: 32px;
    text-align: center;
  }

  p {
    color: $light-color;
    font-family: $font-family-secondary;
    font-size: 18px;
    grid-column: 1/-1;
    margin: 0 auto 32px auto;
    text-align: center;
    max-width: $container-width-sm;
  }

  .tabs {
    align-items: center;
    display: flex;
    gap: 30px;
    grid-column: 1/-1;
    justify-content: center;
    margin-bottom: 32px;

    a {
      padding-bottom: 3px;
      border-bottom: 1px solid transparent;
      align-items: center;
      color: $light-color;
      display: inline-flex;
      font-family: $font-family-secondary;
      font-size: $text-size;
      gap: 8px;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 2px;

      &.active, &:hover {
        color: $text-color;
        border-bottom-color: $text-color;
      }
    }
  }

  .container {
    column-gap: 30px;
    display: grid;
    max-width: $container-width-sm;
  }

  .plan {
    background: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    padding: 24px 30px;

    h3 {
      align-items: center;
      display: grid;
      font-size: 24px;
      gap: 8px;
      grid-template-columns: max-content max-content;
      justify-content: center;
      line-height: 30px;
      margin-bottom: 16px;
      text-align: center;

      span {
        grid-column: 1/-1;

        &.coming-soon {
          font-size: 22px;
        }
      }

      small {
        font-family: $font-family-primary;
        font-size: $text-size;
        font-weight: 400;
        grid-column: 1/-1;
      }
    }

    h4 {
      color: $light-color;
      font-family: $font-family-secondary;
      font-size: $text-size;
      font-weight: 400;
      margin: 0 0 8px 0;
      line-height: 18px;
      letter-spacing: 2px;
    }

    .button {
      margin-top: auto;
      width: 100%;
    }

    ul {
      display: grid;
      font-size: $text-size;
      gap: 16px;
      line-height: 24px;
      margin: 0 0 32px 0;
      padding: 0;

      li {
        display: grid;
        gap: 16px;
        grid-template-columns: 16px 1fr;

        &::before {
          background: url("images/list-check.svg") no-repeat 100%;
          content: "";
          height: 16px;
          margin-top: 5px;
          width: 16px;
        }
      }
    }

    [data-fn="paymentMessage"] iframe {
      margin-top: 16px !important;
    }

    .actions {
      margin-top: auto;
    }

    &.level-team {
      h4 {
        color: $text-color;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    &.buyable {
      .actions {
        display: grid;
        gap: 15px;
      }
    }
  }

  .demo {
    display: flex;
    flex-direction: column;
    gap: 16px;
    grid-column: 1/-1;
    margin-top: 24px;
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    h1 {
      font-size: 40px;
    }

    .demo {
      align-items: center;
    }

    .container {
      grid-template-columns: 1fr 1fr;
    }

    .plan.level-team {
      .actions {
        grid-template-columns: 1fr 1fr;
      }

      .field-warning {
        order: 10;
        grid-column: 1/-1;
      }
    }

    .plan.buyable {
      .actions {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    h1 {
      font-size: 55px;
      margin-bottom: 24px;
    }

    p {
      font-size: 22px;
      margin-bottom: 64px;
    }

    .tabs {
      margin-bottom: 42px;
    }

    .plan {
      h3 {
        font-size: 38px;
        margin-bottom: 32px;
        line-height: 52px;
        row-gap: 0;

        small {
          font-size: $text-size-md;
        }
      }

      h4 {
        font-size: $text-size-md;
        margin-bottom: 16px;
      }

      ul {
        font-size: $text-size-md;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .container {
      grid-template-columns: repeat(2, 50%);
    }
  }
}

.payment-status {
  .container {
    display: grid;
    margin-bottom: 80px;
    gap: 24px;
  }

  .ps-title {
    font-size: 30px;
    line-height: 40px;
    margin: 24px 0 0 0;
    text-align: center;
  }

  .ps-desc {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.1px;
    color: $light-color;
    margin-bottom: 8px;

    br {
      display: none;
    }
  }

  .ps-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    gap: 24px;

    * {
      font-family: $font-family-secondary;
    }

    &:first-of-type {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: _spacing(-1);
        right: _spacing(-1);
        bottom: 0;
        border-bottom: 1px solid #c4c4d1;
      }
    }
  }

  .ps-card {
    --preview-size: 70px;

    display: grid;
    grid-template-columns: _var("preview-size") 1fr;
    border-radius: 12px;
    overflow: hidden;
    align-items: center;
    background-size: _var("preview-size") 100%;
    background-repeat: no-repeat;
    background-color: #fff;

    &._decide {
      background-image: conic-gradient(from 90deg at 50% 50%, #086bff40 0deg 120deg, #0650bf40 120deg 240deg, #50a8ff40 240deg 360deg);
    }

    &._emotions {
      background-image: conic-gradient(from 0deg at 50% 50%, #f7901e26 0deg 60deg, #ea770626 60deg 120deg, #fabc7826 120deg 180deg, #f7901e26 180deg 240deg, #ea770626 240deg 300deg, #fabc7826 300deg 360deg);
    }

    * {
      color: $light-color;
      font-size: 18px;
      line-height: 28px;
    }

    .psc-content {
      padding: 12px;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    svg {
      justify-self: center;
    }

    .psc-title {
      margin: 0;
      color: $text-color;
    }

    .psc-price {
    }

    .psc-quantity {
      font-size: $text-size;
      line-height: 16px;
    }
  }

  .ps-details {
    .psd-title {
      font-size: $text-size-xs;
      align-items: center;
      height: 60px;
      display: flex;
      letter-spacing: 1.8px;
      margin: 0;
    }

    .psd-item {
      height: 50px;
      display: flex;
      font-size: $text-size;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #c4c4d1;

      span {
        font-size: 18px;
        font-weight: 500;
      }

      &._total {
        border-color: $text-color;
      }

      &._promo {
        color: $light-color;
      }
    }
  }

  .ps-step {
    .pss-title {
      font-size: 24px;
      line-height: 30px;
      padding-bottom: 8px;
      border-bottom: 1px solid #000;
      margin-bottom: 24px;
      font-family: $font-family-headings;
    }

    div {
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0.266667px;
    }
  }

  .ps-amz {
    margin-top: 64px;
    grid-column: 1/-1;

    img {
      max-width: 100%;
    }

    .ps-title {
      text-align: left;
      margin: 0 0 24px 0;
    }

    h3 {
      font-family: $font-family-secondary;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.54;
      letter-spacing: -0.1px;
      margin-bottom: 32px;
    }

    p {
      font-size: 16px;
      line-height: 1.77;
      margin: 0;
    }
  }

  @include media-breakpoint-up(md) {
    .container {
      grid-template-columns: .8fr 1.2fr;
    }

    .ps-desc {
      br {
        display: initial;
      }
    }

    .ps-info:first-of-type::after {
      display: none;
    }

    .ps-title {
      grid-column: 1/-1;
    }

    .ps-desc {
      grid-column: 1/-1;
    }
  }

  @include media-breakpoint-up(lg) {
    .container {
      column-gap: 120px;
      grid-template-columns: 440px 1fr;
    }

    .ps-title {
      font-size: 55px;
      line-height: 68px;
    }

    .ps-card {
      --preview-size: 150px;

      svg {
        width: 60px;
        height: 60px;
      }

      .psc-content {
        padding: 25px;
        gap: 4px;
      }

      * {
        font-size: 22px;
        line-height: 34px;
      }
    }

    .ps-details {
      .psd-item {
        height: 65px;
        font-size: 16px;

        span {
          font-size: 22px;
        }
      }
    }

    .ps-step {
      margin-bottom: 32px;

      .pss-title {
        font-size: 38px;
        line-height: 52px;
      }
    }

    .ps-amz {
      margin-top: 150px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 60px;

      img {
        order: 9;
      }

      h3 {
        font-size: 22px;
      }

      p {
        font-size: 18px;
      }
    }
  }
}