.info-slider {
  padding: spacing(0, 1);

  .container {
    align-items: center;
    background: linear-gradient(180deg, #fff 0%, #fff0 100%);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: $container-width-lg;
    padding: 40px 30px;
    text-align: center;
  }

  br {
    display: none;
  }

  h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 32px;
  }

  p {
    font-size: 18px;
    margin-bottom: 32px;
    max-width: breakpoint-min(md);
  }

  .slides {
    align-content: center;
    align-items: center;
    display: grid;
    grid-template-areas: "a" "b";
    justify-content: center;
    justify-items: center;
    max-width: 950px;
    overflow: hidden;
    width: 100%;

    > img {
      display: block;
      grid-area: a;
      margin-bottom: 32px;
      max-width: 200px;
      opacity: 0;
      transform: translateX(100vw);
      transition: transform 1s cubic-bezier(0.22, 0.61, 0.36, 1), opacity .5s cubic-bezier(0.22, 0.61, 0.36, 1);
    }

    h3 {
      align-items: flex-start;
      color: $light-color;
      display: flex;
      font-size: 24px;
      grid-area: b;
      line-height: 30px;
      max-width: 325px;
      opacity: 0;
      text-align: left;
      transform: translateX(100vw);
      transition: transform 1s cubic-bezier(0.22, 0.61, 0.36, 1), opacity .5s cubic-bezier(0.22, 0.61, 0.36, 1);
      width: 100%;

      img {
        margin-right: 16px;
        width: 36px;
      }

      &.current {
        opacity: 1;
        transform: translateX(0);

        & + img {
          opacity: 1;
          transform: translateX(0);
        }
      }

      &.prev {
        transform: translateX(-100vw);

        & + img {
          transform: translateX(-100vw);
        }
      }
    }
  }

  .arrows {
    display: grid;
    gap: 10px;
    grid-template-columns: min-content min-content;
  }

  &.bg-white {
    background: #fff;
  }

  @include media-breakpoint-up(md) {
    br {
      display: initial;
    }

    p {
      margin-bottom: 55px;
    }

    .container {
      position: sticky;
      top: 110px;
    }

    .slides {
      gap: 30px;
      grid-template-areas: "a b";
      justify-content: center;

      > img {
        max-height: calc(100vh - 150px);
        object-fit: contain;
        transform: none !important;
      }

      h3 {
        opacity: 0;
        position: static;
        transform: translateY(200%);

        &.prev {
          transform: translateY(-100%);
        }

        &.current {
          opacity: 1;
          transform: translateY(0);
        }
      }

      > img {
        margin: 0;
        max-width: 250px;
      }
    }

    .arrows {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    .container {
      padding: 72px 30px 50px 30px;
    }

    h2 {
      font-size: 55px;
      line-height: 70px;
    }

    .slides {
      gap: 80px;

      > img {
        max-width: 300px;
      }

      h3 {
        font-size: 38px;
        line-height: 52px;
        max-width: 470px;

        img {
          margin: 10px 28px 0 0;
          width: 60px;
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .slides > img {
      max-width: 350px;
    }
  }
}
