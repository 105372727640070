$font-family-primary: "Tiempos Text", sans-serif;
$font-family-secondary: "National2", sans-serif;
$font-family-headings: "Tiempos Headline", sans-serif;

$bg-color: #f8f4f2;

$primary-color: #f7901e;
$light-color: #8688a1;
$text-color: #000;

$warning-color: #d0021b;

$dark-primary-color: #454561;
$dark-light-color: #5e5e7b;
$dark-text-color: #fff;

$link-color: #1e87f0;
$link-hover: #0f6ecd;

$text-size-xs: 12px;
$text-size: 14px;
$text-size-md: 16px;

$line-height-xs: 1.2;
$line-height: 1.5;
$line-height-md: 1.7;

$container-width-sm: 950px;
$container-width: 1200px;
$container-width-lg: 1380px;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);

