.skills-v2 {
    padding: 40px spacing(1);
    background: #fff;

    .container {
        background: #F8F4F2;
        border-radius: 8px;
        padding: 40px spacing(1);
        text-align: center;
    }

    h2 {
        font-family: $font-family-secondary;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 1.8px;
        text-transform: uppercase;
        color: $text-color;
        margin-bottom: 24px;
    }

    h3 {
        font-weight: 400;
        font-size: 16px;
        line-height: 27px;
        text-align: center;
        color: $text-color;
        margin: 0;

        > span {
            font-size: 20px;
            line-height: 30px;
            color: $primary-color;
            display: flex;
            flex-direction: column;
            height: 106px;
            gap: 8px;
            overflow: hidden;
            padding-top: 30px;
            margin-top: 24px;

            span {
                opacity: .2;
                order: 10;
                transition: margin-top .1s ease-out, opacity .3s ease-out;

                &.prev {
                    margin-top: -30px;
                    opacity: .5;
                    order: 1;
                }

                &.current {
                    opacity: 1;
                    order: 2;
                }

                &.next {
                    opacity: .5;
                    order: 3;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        h3 {
            gap: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            text-align: left;

            > span {
                margin: 0;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        padding: 72px spacing(1);

        .container {
            padding: 72px 64px;
        }

        h2 {
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 2px;
            margin-bottom: 40px;
        }

        h3 {
            font-size: 18px;
            line-height: 32px;

            > span {
                white-space: nowrap;
                font-size: 38px;
                line-height: 52px;
                height: 172px;
            }
        }
    }
}
