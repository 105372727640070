.impact {
    background: #343450;
    padding-top: 40px;
    padding-bottom: 40px;

    h2 {
        font-weight: 600;
        font-size: 23px;
        line-height: 30px;
        text-align: center;
        color: #fff;
        margin: 0;
        grid-column: 1/-1;
    }

    h3 {
        font-family: $font-family-secondary;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        letter-spacing: -0.1px;
        color: #fff;
        margin: 0;
        padding: 32px 30px;
        border-top: 1px solid $light-color;
    }

    img {
        width: 100%;
        display: block;
    }

    .container {
        gap: 24px;
        display: grid;
        justify-items: center;
    }

    .item {
        background: #444460;
        border-radius: 16px;
        overflow: hidden;
    }

    .description {
        grid-column: 1/-1;
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 16px;
        line-height: 27px;
        text-align: center;
        color: #fff;
        max-width: 800px;

        b {
            font-family: $font-family-secondary;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            text-align: center;
            letter-spacing: -0.1px;
            color: #fff;
        }
    }

    @include media-breakpoint-up(md) {
        .container {
            grid-template-columns: 1fr 1fr 1fr;
        }

        h3 {
            padding: 24px;
        }
    }

    @include media-breakpoint-up(lg) {
        .container {
            row-gap: 40px;
            column-gap: 30px;
        }

        h2 {
            font-weight: 600;
            font-size: 38px;
            line-height: 52px;
        }

        h3 {
            font-size: 22px;
            line-height: 34px;
        }

        .description {
            font-size: 18px;
            line-height: 32px;
            margin: 0;

            b {
                font-size: 22px;
                line-height: 34px;
            }
        }
    }
}