.whats-included {
  margin-bottom: 50px;

  .label {
    font-family: $font-family-secondary;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    margin-bottom: 16px;
    grid-column: 1/-1;
  }

  h2 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 16px;
    text-align: center;
  }

  .description {
    max-width: 740px;
    padding: spacing(0, 1);
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: $text-color;
    margin: 0 auto 40px auto;

    span {
      background: #FCE0B6;
      border-radius: 4px;
      padding: 0 2px;
    }

    b {
      font-family: $font-family-secondary;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -0.1px;
      color: $text-color
    }
  }

  .item {
    background: #fff;
    border-radius: 16px;
    margin-bottom: 24px;
    overflow: hidden;
  }

  img {
    border-bottom: 1px solid #e4e8f1;
    display: block;
    height: 230px;
    object-fit: contain;
    padding: 30px;
    width: 100%;
  }

  h3 {
    font-family: $font-family-secondary;
    font-size: 18px;
    line-height: 28px;
    margin: 0;
    padding: 30px 30px 16px 30px;
  }

  p {
    font-size: $text-size-md;
    line-height: 27px;
    margin: 0;
    padding: 0 30px 30px 30px;
  }

  &.light-bg {
    div {
      background: #fcf9f8;
    }
  }

  &.new-home {
    padding-top: 40px;

    img {
      padding: 0;
      height: auto;
      object-fit: cover;
    }
  }

  @include media-breakpoint-up(md) {
    display: grid;
    gap: 24px;
    margin-bottom: 100px;
    grid-template-columns: .75fr .25fr .25fr .75fr;

    .label {
      margin-bottom: -8px;
    }

    div {
      margin: 0;
    }

    h2 {
      grid-column: 1/-1;
      margin: 0;
    }

    .description {
      grid-column: 1/-1;
      margin-bottom: 16px;
      font-size: 18px;
      line-height: 32px;

      b {
        font-size: 22px;
        line-height: 34px;
      }
    }

    .item {
      margin-bottom: 0;
      grid-column: span 2;
    }

    &.odd {
      .item {
        &:nth-last-child(3) {
          grid-row: span 2;
          grid-column: 1;

          img {
            height: 230px;
          }
        }

        &:nth-last-child(-n+2) {
          display: grid;
          grid-template-columns: .6fr .4fr;
          align-content: center;
          grid-column: span 3;

          img {
            grid-row: span 2;
            grid-column: 2;
          }

          h3 {
            padding: 0 40px 16px 60px;
            grid-row: 1;
            align-self: end;
          }

          p {
            padding: 0 40px 0 60px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    gap: 30px;
    margin-bottom: 130px;

    .label {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 2px;
      margin-bottom: -22px;
    }

    img {
      height: 330px;
      padding: 60px;
    }

    h2 {
      font-size: 38px;
      line-height: 52px;
      //margin-bottom: 48px;
    }

    h3 {
      font-size: 22px;
      line-height: 34px;
      padding: 24px 60px 16px 60px;
    }

    p {
      font-size: 18px;
      line-height: 32px;
      padding: 0 60px 40px 60px;
    }

    &.new-home {
      padding-top: 72px;
    }
  }
}
