$warning-icon: url("data:image/svg+xml,%3Csvg fill='none' viewBox='0 0 15 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.75 17a6.75 6.75 0 1 0 0-13.5 6.75 6.75 0 0 0 0 13.5Z' stroke='" + #{$warning-color} + "' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='m8.23 11.833.363-5.181H6.91l.374 5.181h.946Zm.418 1.386c0-.484-.363-.858-.89-.858-.529 0-.892.374-.892.858s.363.869.891.869.891-.385.891-.869Z' fill='" + #{$warning-color} + "'/%3E%3C/svg%3E");

.field-warning {
  font-family: $font-family-secondary;
  color: $warning-color;
  font-size: $text-size-md;
  line-height: 20px;
  letter-spacing: 0.266667px;
  position: relative;
  padding-left: 24px;

  &::before {
    content: "";
    background: #{$warning-icon};
    width: 15px;
    display: block;
    height: 20px;
    background-size: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  a {
    color: inherit;
    font-weight: 500;
  }

  &:empty {
    display: none;
  }
}

.field {
  color: #{$light-color};
  display: flex;
  flex-direction: column;
  font-family: #{$font-family-secondary};
  font-size: #{$text-size};
  gap: 8px;
  line-height: 16px;

  .label {
    &.required::after {
      content: "*";
      padding-left: 5px;
      color: $warning-color;
    }
  }

  select, input:not([type=checkbox]), textarea {
    background: transparent no-repeat calc(100% - 5px) 50%;
    background-size: 0;
    border: 1px solid #{$light-color};
    border-radius: 3px;
    color: #{$text-color};
    display: block;
    font-family: #{$font-family-secondary};
    font-size: #{$text-size-md};
    height: 52px;
    line-height: 20px;
    padding: 0 15px;
    appearance: none;
    outline: none;

    &:focus {
      box-shadow: none;
      border-color: #{$primary-color};
    }
  }

  .inputs-list {
    display: block;
    padding: 0;
    margin: 0;

    > li {
      list-style: none;
    }
  }

  .hs-form-booleancheckbox-display {
    cursor: pointer;
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: 8px;

    input {
      accent-color: $primary-color;
      cursor: pointer;
    }
  }

  &.hs-recaptcha {
    align-items: center;
    order: 999;

    .grecaptcha-logo {
      display: none;
    }

    .grecaptcha-badge {
      width: auto !important;
      height: auto !important;
      box-shadow: none !important;
    }
  }

  select {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m6.2 19.4 4.4-4.5c.4-.3.1-.8-.3-.8H1.4c-.5 0-.7.5-.4.8l4.5 4.5c.2.2.5.2.7 0ZM5.8.4 1.4 4.8c-.4.3-.1.9.3.9h8.9c.5 0 .7-.6.4-.9L6.5.4C6.3.2 6 .2 5.8.4Z' fill='%23000'/%3E%3C/svg%3E");
    background-size: 8px;
  }

  [type="search"] {
    height: 45px;
    background-size: 20px;
    background-position: calc(100% - 10px) 50%;

    &:placeholder-shown {
      background-image: url("images/search.svg");
    }

    &::-webkit-search-cancel-button {
      appearance: none;
    }

    &:not(:placeholder-shown) + .clean {
      visibility: visible;
    }

    &:focus ~ .search-results {
      display: block;
    }
  }

  &.search {
    position: relative;

    .clean {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      visibility: hidden;
    }

    .search-results {
      position: absolute;
      display: none;
      top: 100%;
      left: 0;
      right: 0;
      border: solid $text-color;
      border-width: 0 1px;
      margin: 0;
      background: var(--bg-color);
      z-index: 1;

      a {
        display: block;
        line-height: 50px;
        color: $text-color;
        border-bottom: 1px solid $text-color;
        font-family: $font-family-secondary;
        font-size: 16px;
        letter-spacing: 0.266667px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        padding: 0 16px;
        text-decoration: none;

        &:hover {
          font-weight: 500;
        }
      }

      &:hover {
        display: block;
      }
    }
  }

  &.invalid {
    select, input, textarea {
      background-image: #{$warning-icon};
      background-size: 12px;
      border-color: #{$warning-color};
    }
  }

  &.loading {
    select, input, textarea {
      transition-duration: 0s;
      transition-delay: .2s;
      transition-property: background-size;
      background-image: url("images/loader.svg");
      background-size: 16px;
    }

    .clean {
      display: none;
    }
  }

  .field-warning {
    padding-left: 0;

    &::before {
      content: none;
    }
  }
}

.subscribe-form {
  $subscribe-icon: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 29 20'%3E%3Cpath d='M18 .24a.82.82 0 0 0 0 1.15l7.78 7.8H.82a.82.82 0 0 0 0 1.63h24.96L18 18.62a.82.82 0 0 0 1.16 1.15l9.18-9.19a.82.82 0 0 0 0-1.15L19.15.23a.82.82 0 0 0-1.16 0Z' fill='%23444460'/%3E%3C/svg%3E");

  max-width: 400px;
  align-self: stretch;
  display: flex;
  flex-direction: column;

  form {
    position: relative;
    display: grid;
    gap: 16px;
  }

  label {
    span {
      font-family: $font-family-secondary;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      color: #000000;
      opacity: 1;
    }

    .hs-form-required {
      display: none;
    }
  }

  .hs_error_rollup {
    display: none;
  }

  .hs-error-msgs {
    margin: -4px 0 0 0;
    padding: 0;

    li {
      list-style: none;
      color: #f2545b;
    }
  }

  .field {
    flex: 1;
  }

  .hs-email .input {
    border: 1px solid #C4C4D1;
    border-radius: 3px;
    overflow: hidden;

    input {
      width: 100%;
      padding: 0 52px 0 16px;
      border: none;
      height: 48px;
    }
  }

  .hs-submit {
    position: absolute;
    right: 0;
    top: 24px;
    height: 50px;
    width: 52px;
    background: $subscribe-icon no-repeat 50% 50%;
    background-size: 28px;
    border-radius: 3px;
    overflow: hidden;

    input {
      cursor: pointer;
      opacity: 0;
      width: 100%;
      height: 50px;
      border: none;
      display: block;
      font-family: $font-family-secondary;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      background: transparent;
      color: $light-color;
    }
  }

  .submitted-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    p {
      font-family: $font-family-secondary;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      color: #000000;
      margin: 0;
    }

    &:after {
      content: '';
      width: 32px;
      height: 32px;
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 32 32'%3E%3Cpath d='M16 28a12 12 0 1 0 0-24 12 12 0 0 0 0 24Z' stroke='%232ADA90' stroke-linecap='round' stroke-linejoin='round' fill='%23fff'/%3E%3Cpath d='m21.5 13-7.33 7-3.67-3.5' stroke='%232ADA90' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
  }

  @include media-breakpoint-up(md) {
    &:first-child {
      align-self: center;

      & + img {
        grid-row: 1/1
      }
    }

    .submitted-message {
      align-items: flex-start;
    }
  }
}