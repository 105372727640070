.footer {
  border-top: 1px solid #c4c4d1;
  overflow: hidden;
  padding: 40px 0;

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  svg {
    height: 35px;
    margin-bottom: 20px;
    color: $primary-color;
  }

  #lg {
    --clr: #fff;
  }

  .subscribe-form {
    margin-bottom: 32px;

    > a {
      font-family: $font-family-secondary;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 1.5px;
      text-decoration: underline;
      color: #444460;
      display: block;
      margin-top: 16px;
      text-align: center;

      &:hover {
        text-decoration: none;
      }

      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  nav {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 40px;

    p {
      color: $light-color;
      margin-bottom: 8px;
    }

    a {
      display: block;
      margin-bottom: 8px;
      white-space: nowrap;

      &:last-child {
        margin: 0;
      }
    }
  }

  span {
    color: $light-color;
    font-size: $text-size;
    opacity: .5;
  }

  .dashboard-link {
    display: none;
  }

  &.dark {
    background: $dark-primary-color;
    color: $dark-text-color;

    nav p {
      color: #c4c4d1;
    }

    path {
      fill: $dark-text-color;
    }

    .subscribe-form {
      > a {
        color: #fff;
      }

      label span {
        color: #fff;
      }

      .hs-email .input {
        background: #C4C4D1;

        input {
          color: $light-color;
        }
      }

      .submitted-message p {
        color: #fff;
      }
    }
  }

  &.white {
    background: #fff;
  }

  @include media-breakpoint-up(md) {
    .container {
      align-items: start;
      column-gap: 16px;
      display: grid;
      grid-template-columns: 1fr min-content;

      > * {
        grid-column: 1;
      }

      nav {
        display: flex;
        gap: 0;
        grid-column: 2;
        grid-row: 1 / span 2;
        justify-content: space-between;

        > div {
          margin-right: 20px;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .container nav > div {
      margin-right: 50px;
    }

    .dashboard-link {
      display: block;
    }
  }
}
