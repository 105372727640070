
.hubspot-form {
  margin: 0 auto;
  max-width: $container-width-sm;
  padding: 0 spacing(1) 50px;

  .hbspt-form > iframe {
    width: calc(100% + 20px) !important;
  }

  &.contact-us {
    max-width: $container-width;
    padding: 15px;

    > div {
      margin: 0 auto 0 auto;
      max-width: $container-width-sm;
    }

    @include media-breakpoint-up(md) {
      padding: 70px;
    }
  }

  &.blog-cta {
    max-width: $container-width;

    .content {
      text-align: center;
      background: linear-gradient(197deg, #eaa22d 0%, #ea792d 100%);
      padding: 56px 30px;
      border-radius: 12px 12px 0 0;
      overflow: hidden;
      color: #fff;
      position: relative;

      h1 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 16px;
      }

      p {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 0;
      }

      &::before {
        background: url("images/cta-bg.png") no-repeat bottom left;
        background-size: auto 100px;
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .hbspt-form {
      border-radius: 0 0 12px 12px;
      padding: 32px 24px;
      overflow: hidden;
      background: #f2f4f9;
    }

    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .content {
        text-align: left;
        border-radius: 16px 0 0 16px;

        &::before {
          background-size: auto 150px;
        }

        h2 {
          font-weight: 600;
          font-size: 38px;
          line-height: 52px;
          margin-bottom: 30px;
        }

        p {
          font-size: 18px;
          line-height: 32px;
        }
      }

      .hbspt-form {
        border-radius: 0 16px 16px 0;
      }
    }

    @include media-breakpoint-up(lg) {
      .content {
        padding: 40px 128px 40px 52px;

        &::before {
          background-size: auto 230px;
        }
      }

      .hbspt-form {
        padding: 40px 52px;
      }
    }
  }
}
